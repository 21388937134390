.header {
    position: absolute;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    background: $color-grey-1200;
    //height: $header-height;

    &__main {
        position: relative;
        z-index: 1999;
        display: flex;
        align-items: center;
        height: 128px;
        padding: pxToRem(16) pxToRem(64);
    }

    &__submenu {
        position: absolute;
        display: flex;
        align-self: flex-start;
        column-gap: pxToRem(28);
        color: $color-white;
    }

    &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 54px;
        padding: pxToRem(12) pxToRem(64);
        background-color: $color-grey-1300;
    }

    &__buttons {
        position: absolute;
        //top: 182px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        z-index: 1999;
        justify-content: center;

        .header-cta {
            min-width: pxToRem(240);

            &:first-child {
                border-radius: 0 0 0 4px;

                &:not(&:hover) {
                    background-color: $color-primary-800-80;
                    border-color: $color-primary-800-80;
                }
            }

            &:last-child {
                border-radius: 0 0 4px 0;
            }
        }
    }
}


@include media-breakpoint-down(xl) {
    .js-nav-open .header__buttons {
        z-index: 199;
    }
}

.header__logo {
    display: inline-block;

    img {
        height: 102px;
        width: auto;
    }
}

.header > .container,
.header > .container > .grid {
    height: 100%;
}

.header-grid {
    flex-direction: column;
}

@include media-breakpoint-up(lg) {
    .js-nav-scrolled .header {
        transform: translateY(-100%);
    }
}

.js-nav-scrolled .header {
    background-color: $color-grey-1300-80;

    .header__main {
        height: 76px;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
    }

    .header__logo img {
        height: 56px;
    }

    .header__buttons {
        //top: 130px;
    }
}

.js-nav-scrolled02 .header {
    position: fixed;
    transform: translateY(0);
    transition: all 1s ease;
}

@include media-breakpoint-down(xl) {
    .header {
        &__main {
            height: $header-height-mobile;
            padding: pxToRem(4) pxToRem(20);
        }

        &__logo {
            img {
                height: 80px;
            }
        }

        &__submenu {
            display: none;
        }
    }

    .js-nav-scrolled {
        .header {
            transform: translateY(calc(-100% - 56px));
        }

        .header__logo img {
            height: 56px;
        }

        .header .header__main {
            height: 64px;
        }

        &.js-scroll-up {
            .header {
                transform: translateY(0);
            }
        }
    }
}

/*** Hotelselect ***/

.header__hotelselect {
    display: flex;
    flex-direction: column;
    //align-items: center;
    height: 100%;
    @include getFigmaStyle('Desktop/MD/Regular/Default');

    .standort {
        position: relative;
        display: flex;

        @include media-breakpoint-up(xl) {
            &:hover::before {
                content: '';
                position: absolute;
                top: 22px;
                display: block;
                height: 16px;
                width: 100%;
            }

            &:hover .standort__standort {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .standort__menu {
        display: flex;
        align-items: center;
    }

    .standort__menu::after {
        content: '';
        position: relative;
        top: 0;
        right: 0;
        display: inline-block;
        width: 28px;
        height: 28px;
        background-image: inline-svg('angle-down-filled', $color-white);
        background-size: 28px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .standort__standort {
        position: absolute;
        top: 36px;
        left: 50%;
        z-index: 99;
        transform: translateX(-50%);
        padding: pxToRem(8) 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease;
        white-space: nowrap;
        background-color: $color-white;
        @include getFigmaStyle('Shadow/MD');

        &::before {
            content: '';
            position: absolute;
            top: -9px;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            height: 9px;
            width: 12px;
            border-style: solid;
            border-width: 0 12px 9px 12px;
            border-color: transparent transparent $color-white transparent;
        }
    }

    ul li:not(:last-child) {
        border-bottom: 1px solid $color-grey-200;
    }

    ul li {
        padding: pxToRem(8) pxToRem(25) pxToRem(8) pxToRem(12);

        a {
            position: relative;
            display: inline-flex;
            width: 100%;
            align-items: center;
            padding-left: 23px;
            color: $color-black;
            transition: all .3s ease;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                display: inline-block;
                width: 20px;
                height: 20px;
                background-image: inline-svg('angle-right-filled', $color-grey-400);
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
                transition: all .3s ease;
            }
        }

        @include media-breakpoint-up(lg) {
            &:hover, &:focus {
                a {
                    transform: translateX(13px);
                }

                a::before {
                    left: -5px;
                    margin-right: pxToRem(8);
                    background-image: inline-svg('angle-right-filled', $color-black);
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        .standort__standort {
            position: relative;
            top: 0;
            left: 0;
            display: none;
            width: 100%;
            padding: pxToRem(12) pxToRem(8);
            margin-top: pxToRem(8);
            transform: initial;
            background-color: $color-grey-1200;
            opacity: 1;

            &::before {
                content: none;
            }

            & > li {
                padding: 0;
                border-bottom: none;
            }

            & > li > a {
                width: auto;
                padding: pxToRem(8) 0;
                padding-left: pxToRem(38);
                color: $color-white;

                &::before {
                    width: pxToRem(24);
                    height: pxToRem(24);
                    background-image: inline-svg('arrow-right-md', $color-primary-800);
                    background-size: pxToRem(24);
                }
            }
        }

        .standort, .standort__auswahl, .standort__standort {
            width: 100%;
        }
    }
}

/*** Sprachswitch ***/

.header__language {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    @include getFigmaStyle('Desktop/MD/Regular/Default');

    .language__menu {
        display: flex;
        align-items: center;
    }

    .language__menu::after {
        content: '';
        position: relative;
        top: 0;
        right: 0;
        display: inline-block;
        width: 28px;
        height: 28px;
        background-image: inline-svg('angle-down-filled', $color-white);
        background-size: 28px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    @include media-breakpoint-up(xl) {
        &:hover .language::before {
            content: '';
            position: absolute;
            top: -16px;
            display: block;
            height: 16px;
            width: 100%;
        }
    }

    .language {
        position: absolute;
        top: 36px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease;


        ul {
            position: relative;
            flex-direction: column;
            padding: pxToRem(8) 0;
            background-color: $color-white;
            @include getFigmaStyle('Shadow/MD');

            &::before {
                content: '';
                position: absolute;
                top: -9px;
                left: 50%;
                transform: translateX(-50%);
                display: inline-block;
                height: 9px;
                width: 12px;
                border-style: solid;
                border-width: 0 12px 9px 12px;
                border-color: transparent transparent $color-white transparent;
            }

            li:not(:last-child) {
                border-bottom: 1px solid $color-grey-200;
            }

            li {
                padding: pxToRem(8) pxToRem(36) pxToRem(8) pxToRem(12);

                span.language__item, a.language__item {
                    position: relative;
                    display: inline-flex;
                    width: 100%;
                    align-items: center;
                    padding-left: 23px;
                    color: $color-black;
                    transition: all .3s ease;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background-image: inline-svg('angle-right-filled', $color-grey-400);
                        background-position: center;
                        background-size: 20px;
                        background-repeat: no-repeat;
                        transition: all .3s ease;
                    }
                }

                span.language__item--current {
                    transform: translateX(13px);
                    cursor: initial;

                    &::before {
                        left: -5px;
                        margin-right: pxToRem(8);
                        background-image: inline-svg('angle-right-filled', $color-black);
                    }
                }

                @include media-breakpoint-up(lg) {
                    &:hover, &:focus {
                        span.language__item, a.language__item {
                            transform: translateX(13px);
                        }

                        span.language__item::before, a.language__item::before {
                            left: -5px;
                            margin-right: pxToRem(8);
                            background-image: inline-svg('angle-right-filled', $color-black);
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &:hover, &:focus {
            .language {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        .header__language {
            color: $color-white;
        }

        .language {
            position: relative;
            top: 0;
            left: 0;
            display: none;
            transform: initial;
            opacity: 1;

            &__menu {
                color: $color-white;
            }

            ul {
                padding: pxToRem(12) pxToRem(8);
                margin-top: pxToRem(8);
                transform: initial;
                background-color: $color-grey-1200;
                opacity: 1;

                &::before {
                    content: none;
                }

                & > li {
                    padding: 0;
                    border-bottom: none;
                }

                & > li > a {
                    position: relative;
                    display: block;
                    padding: pxToRem(8) 0;
                    padding-left: pxToRem(38);

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        display: inline-block;
                        width: pxToRem(24);
                        height: pxToRem(24);
                        background-image: inline-svg('arrow-right-md', $color-primary-800);
                        background-size: pxToRem(24);
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                & > li > a > span.language__item {
                    padding: 0;
                    color: $color-white;

                    &::before {
                        content: none;
                    }
                }
            }
        }
    }
}
