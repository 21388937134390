@import "@sx-core/src/scss/element/downloads.scss";

.downloads {
    margin-top: pxToRem(48);
}

ul.downloads__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    column-gap: pxToRem(28);
    row-gap: pxToRem(16);

    li {
        width: auto;
        padding: initial;
        margin: initial;

        &::before {
            content: none;
        }
    }

    .button {
        font-weight: 350;
    }
}