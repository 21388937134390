.header__navigation {
    //@include media-breakpoint-up(xl) {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: pxToRem(64);
        row-gap: pxToRem(8);
    }

    li.level-0 {
        position: relative;

        &.has-children::after {
            content: "";
            position: absolute;
            bottom: -25px;
            left: calc(50% - 11px);
            z-index: 10;
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 11px 8px 11px;
            border-color: transparent transparent $color-white transparent;
            transition: all .3s ease;
            opacity: 0;
        }

        & > a {
            color: $color-white;
            @include getFigmaStyle('Desktop/MD/Regular/Uppercase');

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 1px;
                background-color: $color-white;
                transition: all .3s ease;
            }
        }

        @include media-breakpoint-up(xl) {
            &.active {
                & > a::after {
                    width: 100%;
                }
            }


            &:hover, &:focus {
                a::after {
                    width: 100%;
                }

                & > ul {
                    opacity: 1;
                    pointer-events: auto;
                }

                &.has-children::after {
                    opacity: 1;
                }
            }
        }
    }

    ul ul {
        display: block;
        position: absolute;
        //z-index: 100;
        z-index: 2000;
        background: $color-white;
        padding: pxToRem(16) 0;
        top: 52px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: all .3s ease;
        @include getFigmaStyle('Shadow/MD');

        @include media-breakpoint-up(xl) {
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0px;
                display: block;
                width: 100%;
                height: 30px;
                transform: translateY(-100%);
            }
        }

    }

    li.level-1 {
        min-width: 250px;
        padding: pxToRem(11) pxToRem(28) pxToRem(11) pxToRem(12);

        a {
            position: relative;
            display: inline-flex;
            width: 100%;
            align-items: center;
            padding-left: pxToRem(23);
            color: $color-black;
            @include getFigmaStyle('Desktop/MD/Regular/Default');
            white-space: nowrap;
            transition: all .3s ease;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                display: inline-block;
                width: 20px;
                height: 20px;
                background-image: inline-svg('angle-right-filled', $color-grey-500);
                transition: all .3s ease;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-200;
        }

        @include media-breakpoint-up(xl) {
            &:hover, &:focus {
                a {
                    transform: translateX(13px);

                    &::before {
                        left: -5px;
                        margin-right: pxToRem(8);
                        background-image: inline-svg('angle-right-filled', $color-black);
                    }
                }
            }
        }
    }

    //}

    .navigation-container--mobile {
        display: none;
        flex-direction: column;
        margin-top: pxToRem(40);
    }

    .address {
        margin-top: pxToRem(64);

        .address__item {
            color: $color-white;

            a {
                color: $color-white;
            }
        }

        .address__title {
            margin-bottom: pxToRem(8);
            @include getFigmaStyle('Mobile/MD/Bold/Default');
        }

        .address__phone {
            margin-top: pxToRem(8);
        }

        .address__phone, .address__email {
            span {
                display: inline-block;
                min-width: pxToRem(72);
                @include getFigmaStyle('Mobile/SM/Bold/Default');
            }
        }
    }
}

.socials {
    display: flex;
    column-gap: pxToRem(16);

    a {
        display: inline-block;
        width: 48px;
    }
}

.header__socials {
    display: none;
    margin-top: pxToRem(40);
}

@include media-breakpoint-down(xl) {
    .js-nav-open {
        overflow: hidden;

        .header__navigation {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
        }
    }

    .header__socials {
        display: flex;
    }

    .plus {
        position: absolute;
        right: 0;
        top: 20px;
        display: block;
        width: 24px;
        height: 24px;
        z-index: 10;

        &:after {
            content: '';
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background-image: inline-svg('plus-md', $color-white);
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: center;
            transition: all .3s ease;
        }
    }

    .header__navigation {
        background-color: $color-grey-1300;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 200;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: pxToRem(80) pxToRem(20);
        opacity: 0;
        pointer-events: none;
        overflow: auto;
        transform: translateY(-100%);
        transition: all .6s ease;

        ul ul {
            position: relative;
            display: none;
            top: 0;
            padding: initial;
            background-color: initial;
            opacity: 1;
            transition: none;
            pointer-events: auto;
            left: 0;
            transform: none;
        }

        & > ul {
            //margin-top: pxToRem(100);
            width: 100%;
            row-gap: initial;
        }

        li.level-0 {
            position: relative;
            //padding: pxToRem(10) pxToRem(20);
            width: 100%;

            &.js-subnav-open {
                .plus::after {
                    background-image: inline-svg('minus-md', $color-white);
                }
            }

            & > a {
                position: relative;
                display: block;
                padding: pxToRem(20) pxToRem(38) pxToRem(8) 0;
                margin-bottom: pxToRem(4);
                color: $color-white;
                font-size: pxToRem(20);
                font-weight: 600;
                text-transform: initial;
                border-bottom: 2px solid $color-primary-800;
            }

            & > ul {
                //padding-top: pxToRem(16);
            }
        }

        li.level-1 {
            padding: initial;
            margin-bottom: pxToRem(8);
            border-color: $color-secondary-1000;

            a {
                color: $color-white;
                padding: pxToRem(20) pxToRem(8) pxToRem(8) 0;

                &::before {
                    content: none;
                }
            }
        }

        .navigation-container--mobile {
            display: flex;
            width: 100%;
            row-gap: pxToRem(20);
        }

        .header__hotelselect {
            color: $color-white;
        }
    }
}


.burger {
    display: none;
    position: absolute;
    top: 28px;
    left: 20px;
    z-index: 9999;

    .hamburger:hover {
        opacity: 1;
    }

    .hamburger {
        padding: 12px 12px;
    }

    .hamburger-box {
        width: 24px;
    }

    .hamburger-inner {
        width: 24px;
        height: 2px;
        background-color: $color-white;
        transition: background-color .3s ease;

        &::before, &::after {
            width: 24px;
            height: 2px;
            background-color: $color-white;
        }

        &::before {
            top: -7px;
        }

        &::after {
            bottom: -7px;
        }
    }
}

.js-nav-open .burger {
    .hamburger-inner {
        background-color: $color-white;

        &::before, &::after {
            background-color: $color-white;
        }
    }
}

.js-nav-scrolled .burger {
    top: 8px;
}

.header__logo--nav {
    display: none;
}

.address--nav {
    display: none;
}

@include media-breakpoint-down(xl) {
    .burger, .header__logo--nav, .address--nav, .button.header-cta--nav {
        display: block;
    }
}