.list__pagination {
    margin-top: 20px;
}

.list-grid {
    row-gap: pxToRem(48);
}

.eventlabel {
    position: absolute;
    top: 0;
    left: 20px;
    width: pxToRem(70);
}

.list__item {
    padding-bottom: pxToRem(64);

    &:nth-child(even) {
        .list-item {
            flex-direction: row-reverse;
            justify-content: flex-end;

            &__price {
                top: initial;
                left: initial;
                bottom: 16px;
                right: 16px;
            }

            .eventlabel {
                left: initial;
                right: pxToRem(20);
            }
        }
    }
}

.list-item {
    height: 100%;

    &__image {
        position: relative;
        transition: all .3s ease;
        aspect-ratio: 4 / 5;
    }

    &__body {
        position: relative;
        top: pxToRem(64);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: pxToRem(16);
        color: $color-white;
        text-align: center;
        background-color: $color-grey-1200;
        transition: all .3s ease;
    }

    &__headline {
        @include getFigmaStyle('Desktop/H5/Bold/Default');
        margin-bottom: pxToRem(16);
    }

    .splide__arrow {
        width: pxToRem(40);
        height: pxToRem(40);
        background-size: pxToRem(16);
        opacity: 0;
        transition: all .3s ease;

        &--prev {
            left: pxToRem(16);
            background-image: inline-svg('arrow-left-sm', $color-white);
        }

        &--next {
            right: pxToRem(16);
            background-image: inline-svg('arrow-right-sm', $color-white);
        }
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            .list-item__image:not(.img--empty) {
                box-shadow: 0 4px 36px rgba($color-black, 0.2);
            }

            .list-item__body {
                background-color: $color-grey-1300;
                box-shadow: 0 4px 36px rgba($color-black, 0.2);
            }

            .splide__arrow {
                opacity: 1;
            }
        }
    }

    &__eventdate {
        margin-bottom: pxToRem(16);
        @include getFigmaStyle('Desktop/XS/Bold/Default');
    }
}

.list, .teaser, .slider-small {
    .price {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: pxToRem(80);
        height: pxToRem(80);
        color: $color-white;
        @include getFigmaStyle('Desktop/H5/Medium/Default');
        text-align: center;
        background-color: $color-primary-800;
        border-radius: 50%;
        transform: rotate(-12deg);

        .price__price {
            margin-bottom: pxToRem(8);
        }

        .price__prefix {
            display: block;
            @include getFigmaStyle('Desktop/XS/Regular/Default');
        }
    }
}

@include media-breakpoint-down(md) {
    .list__item {
        padding-bottom: initial;

        &:nth-child(even) .list-item__price {
            top: 16px;
            right: 16px;
        }
    }

    .list-item {
        &__image {
            aspect-ratio: 1/1;

            &.img--empty {
                display: none;
            }
        }

        &__body {
            top: initial;
            padding: pxToRem(28) pxToRem(16);
        }
    }
}