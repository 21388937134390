.content p {
    color: $color-grey-1000;

    strong {
        color: $color-black;
    }
}

.article {
    text-align: center;

    .headline {
        margin-bottom: pxToRem(16);
    }

    &__headline--subline {
        display: inline-block;
        margin-bottom: pxToRem(12);
        @include getFigmaStyle('Desktop/H5/Bold/Default');
    }

    &__headline:not(.headline--subline) {
        margin-bottom: pxToRem(48);
        @include getFigmaStyle('Desktop/H1/Bold/Default');
    }
}