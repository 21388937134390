
.video {
    background: #eee;//$color-grey-100;
}

.keyvisual .video__unmute {
    background-color: rgba($color-white, 0.6);
}

.keyvisual .video__unmute.active {
    background-color: rgba($color-white, 0.9);
}