.tides {
    position: absolute;
    top: -84px; //padding-top .footer__top 56px + 28px Verschiebung
    width: 180px;
    margin-right: pxToRem(36);
    @include getFigmaStyle('Shadow/MD');

    &__main {
        position: relative;
        display: flex;
        max-height: 228px;
        padding: pxToRem(10);
        background-color: $color-grey-1200;
    }

    &__img {
        width: 180px;
    }

    &__txt {
        display: flex;
        justify-content: center;
        padding: pxToRem(2) pxToRem(2) pxToRem(7);
        color: $color-white;
        @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
        background-color: $color-grey-1200;
    }
}


.vol-container {
    position: absolute;
    top: 28px;
    right: -60px;
    display: flex;
    flex-direction: column;
    height: auto;
}

.vol {
    position: relative;
    display: inline-block;
    width: 60px;
    padding: pxToRem(5);
    color: $color-white;
    @include getFigmaStyle('Desktop/XS/Regular/Default');
    text-align: center;
    background-color: $color-grey-1200;
    transition: all .3s ease;

    &:not(:last-child) {
        margin-bottom: pxToRem(2);
    }

    &:hover {
        background-color: $color-primary;
    }
}

@include media-breakpoint-down(lg) {
    .tides {
        position: relative;
        top: pxToRem(-28);
    }
}