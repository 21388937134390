.stoerer {
    position: absolute;
    height: auto;
    max-width: 350px;
    z-index: 10;
}

.stoerer--1 {
    top: 75px;
    right: 75px;
}

.stoerer--2 {
    left: 80px;
    bottom: 50px;
}

.swinger {
    position: relative;
}

.swing-general {
    animation-name: swing-general;
    animation-duration: 1.1s;
    animation-fill-mode: both;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 53% 0;
}

@keyframes swing-general {
    100% {
        transform: rotate(15deg);
    }
}

@include media-breakpoint-down(md) {
    .stoerer {
        max-width: 220px;
    }

    .stoerer--1 {
        top: 75px;
        right: 30px;
    }

    .stoerer--2 {
        left: 35px;
        bottom: 35px;
    }

    .keyvisual__gallery-aufkleber:not(.stoerer--2) {
        display: none;
    }
}