.accordion > .grid {
    row-gap: pxToRem(12);
}

.accordion-item {
    background-color: $color-grey-1200;
    border-bottom: none;

    &__header {
        position: relative;
        padding: pxToRem(15) pxToRem(64);
        cursor: pointer;

        &::after {
            content: '';
            display: flex;
            position: absolute;
            top: calc(50% - 14px);
            right: 28px;
            width: 28px;
            height: 28px;
            background-image: inline-svg('plus-md', $color-white);
            background-size: 28px;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all .3s ease;
        }
    }

    &__header h3 {
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
        text-align: center;
    }

    &__content.content {
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Default');

        a, * {
            color: $color-white;
        }

        ul:first-child, table:first-child {
            margin-top: 0;
        }

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
            &:first-child {
                margin-top: initial;
            }
        }
    }

    &__body {
        row-gap: pxToRem(48);
        padding: pxToRem(48);
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Default');

        .content {
            padding: initial;
        }
    }

    .accordion-item__image {
        margin-top: pxToRem(48);
    }

    ul > li::before {
        background-image: inline-svg('dot', $color-white);
    }
}

.accordion__item:not(:first-child) {
    //margin-top: pxToRem(12);
}

.accordion__item.uk-open {
    .accordion-item__header {
        &::after {
            background-image: inline-svg('minus-md', $color-white);
        }
    }
}

@include media-breakpoint-down(md) {
    .accordion-item {
        &__body {
            padding: pxToRem(28) pxToRem(20);
        }
    }
}

/*** FAQ-Suche ***/

.faq-search {
    margin-bottom: pxToRem(48);

    #search-submit {
        border-radius: 0 4px 4px 0;
    }
}