.footer__partner {
    .partner__logo {
        max-width: calc(100% / 9);
    }
}

@include media-breakpoint-down(lg) {
    .footer__partner {
        .partner__logo {
            max-width: initial;
        }
    }
}