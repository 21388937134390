/*** Blog Übersicht ***/

.teaser-item-blog {
    display: block;
    padding: pxToRem(40) pxToRem(20) pxToRem(20);
    background-color: $color-grey-1100;
    color: $color-white;

    &__headline {
        position: relative;
        @include getFigmaStyle('Desktop/H4/Bold/Default');
        padding-bottom: pxToRem(16);
        margin-bottom: pxToRem(16);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 30px;
            height: 2px;
            background-color: $color-white;
        }
    }

    &__teasertext {
        position: relative;
        padding-bottom: pxToRem(16);
        margin-bottom: pxToRem(16);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #2A2F31;
        }
        p:last-child {
            margin-bottom: 0 !important
        }
    }

    & > .button {
        margin-top: pxToRem(16);
    }

    &__image {
        margin-top: pxToRem(36);
    }
}

.column1, .column2, .column3 {
    display: flex;
    flex-direction: column;
    row-gap: pxToRem(12);
}

.teaser-blog__item-container--mobile {
    display: none;
}

@include media-breakpoint-down(lg) {
    .teaser-grid {
        row-gap: pxToRem(20);
    }

    .teaser-blog__item-container--mobile {
        display: block;
    }

    .column1, .column2, .column3 {
        display: none;
    }
}

/*** News-Slider ***/

.slider-news {
    .splide__track {
        padding: pxToRem(28) 0;
        background-color: $color-secondary-1300;
    }

    .splide__pagination {
        display: none;
    }
}

.slider-item-news {
    display: block;
    padding: pxToRem(16) pxToRem(12) pxToRem(12);
    color: $color-white;
    background-color: $color-grey-1200;

    &__headline {
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
        text-align: center;
    }

    &__label {
        margin-top: pxToRem(6);
        @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
        text-align: center;
    }

    &__image {
        margin-top: pxToRem(16);
    }
}