.intermediate {
    height: auto;
    overflow: hidden;
    position: relative;

    .splide__pagination {
        bottom: pxToRem(36);
    }

    .splide__arrows {
        opacity: 0;
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            .splide__arrows {
                opacity: 1;
            }
        }
    }

    &__gallery-aufkleber.gallery-aufkleber {
        top: 30%;
        right: 15%;
        left: initial;
        bottom: initial;
    }
}