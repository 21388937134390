.footer {
    a, p {
        color: $color-white;
    }

    &__top {
        position: relative;
        padding-top: pxToRem(56);
        padding-bottom: pxToRem(96);
        background-color: $color-grey-1100;
    }

    &__events {
        background-color: $color-grey-1200;

        .eventdate span {
            background-color: transparent;
            border: none;
        }
    }

    &__partner {
        padding-top: pxToRem(48);
        padding-bottom: pxToRem(48);
        background-color: $color-white;

        .splide__pagination {
            display: none;
        }
    }

    &__address {
        display: flex;
        padding: pxToRem(18) 0;
        color: $color-white;
        @include getFigmaStyle('Desktop/XS/Regular/Default');
        text-align: center;
        background-color: $color-primary-800;
    }

    &__socials {
        position: relative;
        display: flex;
        column-gap: pxToRem(16);
        padding-right: pxToRem(28);
        margin-right: pxToRem(28);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: 1px;
            height: 40px;
            background-color: $color-grey-700;
        }

        img {
            width: 40px;
            height: auto;
        }
    }

    &__cookies {
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: all .3s ease;

        &::before {
            content: '';
            display: inline-block;
            width: 36px;
            height: 36px;
            margin-right: pxToRem(12);
            background-image: inline-svg('cookie', $color-white, transparent);
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            border: 2px solid $color-white;
            border-radius: 50%;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus, &:active {
                text-decoration-color: $color-white;
            }
        }

        &.footer__cookies--mobile {
            color: $color-white;
            text-decoration: none;
        }
    }
}

.footer__newsletter {
    padding-left: pxToRem(56);

    &--headline {
        margin-bottom: pxToRem(16);
        color: $color-white;
    }

    &--content {
        margin-bottom: pxToRem(28);
        color: $color-white;
    }

    &-inner {
        display: flex;
        align-items: center;
        margin-top: pxToRem(36);
    }
}

.footer__navigation {
    padding-left: pxToRem(56);

    ul {
        display: flex;
        flex-wrap: wrap;
        row-gap: pxToRem(4);
    }

    ul > li {
        width: 50%;
        padding-right: pxToRem(16);
    }

    ul > li > a {
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }
}

.footer__partner {
    .splide__slide {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 54px;

        img {
            max-height: 54px;
        }
    }

    .footer__items {
        flex-wrap: wrap;
        justify-content: center;
    }

    .partner__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: calc(100% / 11);
        padding: pxToRem(16);

        img {
            max-height: 100px;
            width: 100%;
        }
    }
}

.footer__partner02 {
    row-gap: pxToRem(16);
    margin-top: pxToRem(28);
    padding-top: pxToRem(28);
    border-top: 1px solid $color-black;

    .partner--eu {
        max-width: 256px;
    }

    .partner--sh {
        max-width: 293px;
    }

    div:last-child {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

@include media-breakpoint-down(xl) {
    .footer__navigation {
        padding-left: initial;
        margin-top: pxToRem(36);
    }

    .footer__partner {
        .partner__logo {
            max-width: initial;
            flex-basis: calc(100% / 5);
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer {
        &__top {
            padding-top: initial;
        }

        &__tides {
            display: flex;
            justify-content: center;
        }

        &__navigation {
            margin-top: initial;
        }
    }

    .footer__socials {
        position: initial;
        justify-content: center;
        width: 100%;
        padding-right: initial;
        padding-bottom: pxToRem(16);
        margin-bottom: pxToRem(16);
        margin-right: initial;
        border-bottom: 1px solid $color-grey-700;
    }

    .footer__newsletter {
        padding-left: initial;
        margin-bottom: pxToRem(36);

        &-inner {
            flex-direction: column;
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer__partner {
        .footer__items {
            column-gap: pxToRem(100);
        }
        .partner__logo {
            flex-basis: calc(50% - 50px);
            padding: initial;
            padding-bottom: pxToRem(36);

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .footer__partner02 {
        flex-direction: column;

        div, div:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}