@import "@sx-core/src/scss/base/error.base.scss";
@import "@sx-core/src/scss/element/error.scss";

.error {
    margin-top: $header-height;
}

@include media-breakpoint-down(xl) {
    .error {
        margin-top: $header-height-mobile;
    }
}