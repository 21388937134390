
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
}

body {
    overflow-x: hidden;
    font-family: $font-family-primary;
    font-weight: $base-font-weight;
    color: $font-color;
    background-image: url('/assets/img/bg_kachel.jpg');
}

.css-transitions-only-after-page-load *:not(img,picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

a {
    text-decoration: none;
}

ins {
    text-decoration: none;
}

address {
    font-style: normal;
}

h1, .h1 {
    @include getFigmaStyle('Desktop/H1/Bold/Default');
}

h2, .h2 {
    @include getFigmaStyle('Desktop/H2/Bold/Default');
}

h3, .h3 {
    @include getFigmaStyle('Desktop/H3/Bold/Default');
}

h4, .h4 {
    @include getFigmaStyle('Desktop/H4/Bold/Default');
}

h5, .h5 {
    @include getFigmaStyle('Desktop/H5/Bold/Default');
}

h6, .h6 {
    @include getFigmaStyle('Desktop/H6/Bold/Default');
}

p {
    @include getFigmaStyle('Desktop/MD/Regular/Default');
}

.content {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin-top: pxToRem(48);
    }
}

.modulspacing-sm {
    margin-top: $modulspacing-desktop-s;
    margin-bottom: $modulspacing-desktop-s;
    @include media-breakpoint-down(md) {
        margin-top: $modulspacing-mobile-s;
        margin-bottom: $modulspacing-mobile-s;
    }
}

.modulspacing {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
    }
}

.bgcolorspacing {
    padding-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        padding-top: $modulspacing-mobile-m;
        padding-bottom: $modulspacing-mobile-m;
    }
}

.bgcolor {
    background: $color-background;
}

.text-center {
    text-align: center;
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }
}