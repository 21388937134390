.gallery-grid {
    position: relative;
    justify-content: center;
}

/*** Polaroid ***/

.gallery-item--polaroid {
    &:nth-child(odd) {
        margin-top: pxToRem(28);
        transform: rotate(6deg);
    }

    &:nth-child(even) {
        transform: rotate(-4deg);
    }

    .gallery__item {
        display: block;
        animation-name: swing;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        transform-origin: 50% 8px;
        animation-direction: alternate;
        animation-iteration-count: infinite;
    }

    &:nth-child(2) .gallery__item {
        animation-duration: 1.2s;
    }

    &:nth-child(3) .gallery__item {
        animation-duration: 1.1s;
    }

    .image__outer {
        padding: pxToRem(16);
        background-color: $color-white;
        @include getFigmaStyle('Shadow/SM');

        .desc {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: pxToRem(16) pxToRem(8) 0;
            color: $color-grey-1000;
            text-align: center;
            @include getFigmaStyle('Desktop/Polaroid/Description');
            background-color: $color-white;
        }
    }
}

@keyframes swing {
    100% {
        transform: rotate(1deg);
    }
}

@include media-breakpoint-down(md) {
    .gallery-item--polaroid {
        .image__outer .desc {
            font-family: $font-family-secondary;
        }
    }
}

@include media-breakpoint-down(sm) {
    .gallery--polaroid.gallery-items--1, .gallery--polaroid.gallery-items--2, .gallery--polaroid.gallery-items--3 {
        & > .container {
            padding: initial;
        }

        .gallery-grid.grid.grid--gutter {
            margin: initial;
        }

        .gallery-grid {
            overflow: hidden;
            aspect-ratio: 2/3;
        }

        .gallery-item {
            position: absolute;

            &:nth-child(1) {
                right: -30%;
            }

            &:nth-child(2) {
                left: -30%;
                margin-top: pxToRem(28);
            }

            &:nth-child(3) {
                top: 28%;
            }
        }
    }
}

/*** 2 Bilder mit Klammer ***/

.gallery-2pics {
    .gallery__item {
        .image__outer {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: inline-block;
                width: 95px;
                height: 75px;
                background-image: url('/assets/img/klammer.png');
                background-position: center;
                background-size: 95px 75px;
                background-repeat: no-repeat;
            }
        }
    }

    &-item:first-child {
        .image__outer::after {
            bottom: 20px;
            left: -57px;
            transform: rotate(180deg);
        }
    }

    &-item:nth-child(2) {
        .image__outer::after {
            top: 20px;
            right: -57px;
        }
    }

    &__gallery-aufkleber.gallery-aufkleber {
        top: 64%;
        right: -50px;
    }
}

@include media-breakpoint-down(lg) {
    .gallery-2pics {
        &-item {
            &:first-child {
                .image__outer::after {
                    bottom: initial;
                    top: -25px;
                    left: 28px;
                    transform: rotate(-90deg);
                }
            }

            &:nth-child(2) {
                .image__outer::after {
                    top: initial;
                    bottom: -25px;
                    right: 28px;
                    transform: rotate(90deg);
                }
            }
        }

        .gallery__item .image__outer::after {
            width: 50px;
            height: 39px;
            background-size: 50px 39px;
        }

        &__gallery-aufkleber.gallery-aufkleber {
            top: initial;
            bottom: 50%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

/*** Galerie Zimmer ***/

.gallery--room {
    .gallery-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: pxToRem(96);
        grid-row-gap: pxToRem(96);
    }

    @include media-breakpoint-down(md) {
        .gallery-grid {
            grid-template-columns: 1fr;
            grid-row-gap: pxToRem(4);
        }
    }
}

/*** Aufkleber ***/

.gallery-aufkleber {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 160px;

    .image__outer {
        position: relative;
    }

    img {
        opacity: 1;
        animation-name: swing-bpl;
        animation-duration: .8s;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        transform-origin: 50% 0;
        animation-direction: alternate;
        animation-iteration-count: infinite;
    }

    picture {
        position: relative !important;
        display: block;
    }

    img.gallery-aufkleber-hover {
        position: absolute;
        top: 0;
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }

    &:hover {
        img.gallery-aufkleber-hover {
            opacity: 1;
        }
    }

    &.spin {
        img {
            animation-name: swing-bpl-spin;
            animation-duration: 1.2s;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.25, 0.1, 0, 1.020);
            transform-origin: 50% 0;
            animation-iteration-count: 1;
        }

        img.gallery-aufkleber-hover {
            opacity: 1;
        }
    }
}

@keyframes swing-bpl {
    100% {
        transform: rotate(5deg);
    }
}

@keyframes swing-bpl-spin {
    100% {
        transform: rotate(-720deg);
    }
}

@include media-breakpoint-down(md) {
    .gallery-aufkleber {
        width: 120px;
    }
}