.jobs {
    .s-jobs {
        width: 83.3333333333%;
        margin-left: 8.3333333333%;
        background: none;
        border-bottom: none;
    }

    .s-job {
        align-items: initial;
        padding: initial;
        color: $color-white;
        background-color: $color-grey-1200;
        border-radius: 0 4px 4px 0;

        &:not(:last-child) {
            margin-bottom: pxToRem(12);
            border: none;
        }
    }

    .s-job__left {
        padding: pxToRem(14) pxToRem(8) pxToRem(14) pxToRem(28);
    }

    .s-job__title {
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
    }

    .s-job__right {
        margin: initial;
    }

    .s-job__link {
        height: 100%;

        a {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            @include getFigmaStyle('Desktop/MD/Medium/Default');
            transition: all .3s ease;
            @include button-size($btn-padding-y-sm, 48px, $btn-font-size-sm, $btn-line-height-sm);
            @include button-variant($color-primary-800, $color-primary-800, $color-white,
            $hover-background-color: $color-primary-900,
            $hover-border-color: $color-primary-900,
            $active-background-color: $color-primary-900,
            $active-border-color: $color-primary-900);
            border-radius: 0 4px 4px 0;

            &::before, &::after {
                content: '';
                position: absolute;
                display: block;
                width: 24px;
                height: 24px;
                background-image: inline-svg('dot', $color-primary-900, $color-primary-900, $color-primary-1000);
                background-size: 24px;
                background-position: center;
                background-repeat: no-repeat;
                transition: all .3s ease;
            }

            &::before {
                left: 12px;
            }

            &::after {
                right: 12px;
            }

            &:hover, &:focus, &:active {
                &::before, &::after {
                    background-image: inline-svg('dot', $color-white, $color-white, $color-white);
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .jobs {
        .s-jobs {
            width: 100%;
            margin-left: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .jobs {
        .s-job {
            flex-direction: column;
            border-radius: 0 0 4px 4px;
        }

        .s-job__title {
            text-align: center;
        }

        .s-job__right {
            width: 100%;
        }

        .s-job__link {
            width: 100%;

            a {
                width: 100%;
                border-radius: 0 0 4px 4px;
            }
        }
    }
}