@import "@sx-core/src/scss/element/teaser.scss";

.teaser-grid {
    justify-content: center;
}

.teaser__item {
    &:nth-child(1) .teaser-item {
        margin-top: pxToRem(64);
    }

    &:nth-child(3) .teaser-item {
        margin-top: pxToRem(112);
    }
}

.teaser__item.item--double .teaser-item {
    margin-top: initial;
}

.teaser-item {
    //position: relative;
    display: block;
    @include getFigmaStyle('Shadow/SM');
    transition: all .4s ease;

    &__image {
        position: relative;
    }

    &__headline {
        padding: pxToRem(15) pxToRem(8);
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
        text-align: center;
        background-color: $color-grey-1200;
    }

    &__body {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 0;
        background-color: rgba($color-grey-1300, .5);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        overflow: hidden;
        transition: all .4s ease;
    }

    &__teasertext {
        position: relative;
        top: pxToRem(16);
        margin: pxToRem(28);
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
        text-align: center;
        opacity: 0;
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            box-shadow: 0 4px 36px rgba($color-black, .2);


            .teaser-item__body {
                height: 100%;
            }

            .teaser-item__teasertext, .teaser-item-body__link {
                animation-name: teaser-hover;
                animation-duration: .8s;
                animation-fill-mode: forwards;
                animation-delay: .15s;
            }
        }
    }
}

/*** Special Room Teaser ***/

.teaser-item__body--room {
    flex-direction: column;
    row-gap: pxToRem(1);
    background: none;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;


    .teaser-item-body__item {
        display: flex;
        flex: 100%;
        width: 100%;
        background-color: rgba($color-grey-1300, .5);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

        .teaser-item-body__link {
            position: relative;
            top: pxToRem(16);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            color: $color-white;
            opacity: 0;
        }
    }
}

@keyframes teaser-hover {
    0% {
        top: pxToRem(16);
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@include media-breakpoint-down(lg) {
    .teaser-grid {
        row-gap: pxToRem(4);
    }

    .teaser-item--room {
        .headline {
            display: none;
        }

        .teaser-item__body {
            position: relative;
            height: auto;
            row-gap: pxToRem(4);
        }

        .teaser-item-body__item {
            width: 100%;
            padding: pxToRem(15);
            text-align: center;
            background-color: $color-grey-1200;

            .teaser-item-body__link {
                top: initial;
                color: $color-white;
                font-size: pxToRem(16);
                font-weight: 350;
                text-transform: uppercase;
                opacity: 1;
            }
        }
    }

    .teaser__item:nth-child(3) .teaser-item {
        margin-top: initial;
    }
}

/*** || Teaser Event ***/

.teaser-event-upcoming {
    position: relative;
    top: -40px;
    row-gap: pxToRem(8);

    &__item {
        z-index: 2;
    }
}

.teaser-event-item {
    &__headline, &__eventdate {
        padding: pxToRem(9) pxToRem(8);
        color: $color-white;
        @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
        text-align: center;
        background-color: $color-grey-1300;
        transition: all .3s ease;
    }

    &__eventdate {
        @include getFigmaStyle('Desktop/XS/Regular/Default');
    }

    .teaser-event-item__eventdate.eventdate--is-selected {
        margin: initial;
    }
}

.teaser-event-upcoming__link {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .event-link {
        position: relative;
        display: flex;
        align-items: center;
        height: 56px;
        width: calc(100% - 22px);
        padding: pxToRem(8) pxToRem(12) pxToRem(8) pxToRem(56);
        margin-left: 22px;
        @include getFigmaStyle('Desktop/LG/Bold/Default');
        background: linear-gradient(to right, rgba(64, 68, 75, 1) 0%, rgba(64, 68, 75, 0) 100%);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -22px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 28px 22px 28px 0;
            border-color: transparent rgba(64, 68, 75, 1) transparent transparent;
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser-event-upcoming {
        &::before, &::after {
            content: none;
        }

        &__item {
            a {
                position: relative;
                display: block;
            }
        }
    }

    .teaser-event-upcoming__link {
        justify-content: center;
        min-height: 150px;
        transform: rotate(90deg);

        .event-link {
            display: flex;
            align-items: center;
            width: auto;

            &::before {
                top: 0;
            }
        }
    }
}

/*** Teaser Polaroid ***/

.teaser-polaroid__item {
    &:nth-child(odd) {
        margin-top: pxToRem(28);
        transform: rotate(6deg);
    }

    &:nth-child(even) {
        transform: rotate(-4deg);

        .teaser-polaroid-item {
            animation-duration: 1.2s;
        }
    }

    &:nth-child(3n) .teaser-polaroid-item {
        animation-duration: 1.1s;
    }
}

.teaser-polaroid-item {
    display: block;
    padding: pxToRem(16);
    background-color: $color-white;
    @include getFigmaStyle('Shadow/SM');
    animation-name: swing;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    transform-origin: 50% 8px;
    animation-direction: alternate;
    animation-iteration-count: infinite;

    &__headline {
        color: #4F545E;
        @include getFigmaStyle('Desktop/Polaroid/Description');
        text-align: center;
    }

    @include media-breakpoint-down(md) {
        &__headline {
            display: none;
        }
    }
}