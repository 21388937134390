.price {
    @include getFigmaStyle('Desktop/LG/Medium/Default');

    &.article__price {
        display: inline-block;
        margin-top: pxToRem(48);
    }
}

.bookingbar-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: pxToRem(28);
    row-gap: pxToRem(12);
}

.factbox {
    &__facts {
        //display: flex;
        //flex-wrap: wrap;
        display: grid;
        grid-template-columns:
    repeat(auto-fill,
        minmax(clamp(clamp(
            100%/(3 + 1) + 0.1%,
            (1200px - 100vw)*1000,
            100%/(2 + 1) + 0.1%),
        (576px - 100vw)*1000,
        100%), 1fr));
        column-gap: pxToRem(36);
        row-gap: pxToRem(8);
        padding: pxToRem(48) pxToRem(28);
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
        background-color: $color-grey-1100;
    }

    &__footer {
        padding: pxToRem(28) pxToRem(48);
        color: $color-white;
        text-align: center;
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
        background-color: $color-grey-1200;
    }
}