
.keyvisual {
    position: relative;
    overflow: hidden;
    height: auto;
    margin-top: $header-height;

    .keyvisual__wrapper {
        height: 100%;

        & > ul {
            height: 100%;
        }
    }

    .slide {
        height: 100%;
    }

    .image__outer {
        height: 100%;
    }

    .image {
        position: unset; //reset
        height: 100%;
    }

    .image img {
        position: absolute; //reset
        height: 100%;
    }

    .keyvisual__slider, .splide__track {
        height: 100%;
    }

    &__gallery-aufkleber.gallery-aufkleber {
        //top: 30%;
        //right: 15%;
        top: initial;
        bottom: calc(10% + #{pxToRem(160)});
        right: 10%;

        img {
            height: auto;
        }

        &.stoerer--2 {
            left: 15%;
            top: initial;
            bottom: calc(10% + 160px);
        }

        img.gallery-aufkleber-hover {
            z-index: 10;
        }

        &:hover img.gallery-aufkleber-hover, &.spin img.gallery-aufkleber-hover {
            opacity: 1;
            transition: opacity 1s ease-in-out;
        }
    }
}

.keyvisual {
    height: calc(100vh - $header-height);
}

.keyvisual-empty {
    height: $header-height;
}

.gallery-cta {
    position: fixed;
    //top: 50vh;
    top: calc($sx-headerheight + pxToRem(54) + pxToRem(36));
    right: 0;
    z-index: 100;
    display: block;
    height: pxToRem(44);
    transform: rotate(-90deg) translateX(50%);
    transform-origin: right bottom;
    overflow: hidden;

    &__button.button {
        height: 100%;
        transform: translateY(4px);
        border-radius: 4px 4px 0 0;

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: pxToRem(6);
            background-image: inline-svg('aperture', $color-white, transparent);
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
        }

        @include media-breakpoint-up(lg) {
            &:hover, &:focus {
                transform: translateY(0);
            }
        }
    }

    &__button--merch.button {
        &::before {
            background-image: inline-svg('shopping-bag', $color-white, transparent);
        }
    }
}

@include media-breakpoint-down(xl) {
    .keyvisual--big {
        // dieser Fall tritt vermutlich nie auf (siehe unten)
        //height: calc(100vh - $header-height-mobile);
        height: auto;
    }

    .keyvisual {
        margin-top: $header-height-mobile;
    }

    .keyvisual-empty {
        height: $header-height;
    }
}

@include media-breakpoint-down(md) {
    .keyvisual {
        &__gallery-aufkleber.gallery-aufkleber.stoerer--2 {
            right: initial;
            top: initial;
            left: pxToRem(20);
            bottom: calc(#{pxToRem(20)} + 120px);
        }
    }
}


//@supports (height: 100svh) {
//    @include media-breakpoint-down(md) {
//        .keyvisual--big {
//            height: calc(100vh - #{$header-height-mobile});
//        }
//    }
//}

/*** || Claim ***/

.claim {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    color: $color-white;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: $color-black-alpha-400;
        pointer-events: none;
    }

    &__headline {
        z-index: 10;
        @include getFigmaStyle('Desktop/Xxl/Bold/Default');
        text-align: center;
        text-shadow: 0 2px 6px rgba($color-black, .08);
    }

    &__subline {
        display: inline-block;
        z-index: 10;
        padding: pxToRem(3) pxToRem(12);
        margin-bottom: pxToRem(16);
        color: $color-white;
        @include getFigmaStyle('Desktop/H5/Regular/Default');
        text-align: center;
        text-shadow: 0 2px 6px rgba($color-black, .08);
    }

    &__link {
        z-index: 10;
        margin-top: pxToRem(48);
    }
}

@include media-breakpoint-down(md) {
    .claim {
        padding-top: 10%;
    }
}

@include media-breakpoint-down(sm) {
    .keyvisual {
        &__gallery-aufkleber.gallery-aufkleber {
            &.stoerer--2 {
                left: pxToRem(20);
                bottom: calc(#{pxToRem(20)} + 120px);
            }
        }
    }
}