@import "@sx-core/src/scss/base/filter.base.scss";
@import "@sx-core/src/scss/element/filter.scss";

.filter-grid {
    justify-content: center;
    row-gap: pxToRem(12);
}

.filter {
    &__field {
        height: 56px;
    }

    select {
        -webkit-appearance: none;
        appearance: none;
    }

    select, .chosen-container, .chosen-container .chosen-single {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        @include getFigmaStyle('Shadow/MD');
    }

    select, .chosen-container .chosen-single {
        padding: 0 pxToRem(40) 0 pxToRem(24);
        background-size: 28px;
        background-image: inline-svg('angle-down-filled', $color-primary-800);
        background-position: top 50% right pxToRem(12);
        background-repeat: no-repeat;
        border: none;
        transition: all .3s ease;
    }

    .chosen-container.chosen-with-drop.chosen-container-active .chosen-single {
        background-size: 28px;
        background-image: inline-svg('angle-up-filled', $color-primary-800);
        background-position: top 50% right pxToRem(12);
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .chosen-pseudo:hover, .chosen-pseudo:focus, .chosen-container .chosen-single:hover, .chosen-container .chosen-choices:hover, .chosen-container.chosen-with-drop .chosen-single, .chosen-container.chosen-container-active .chosen-single, .chosen-container.chosen-with-drop .chosen-choices, .chosen-container.chosen-container-active .chosen-choices {
        border: none;
        border-radius: 4px;
        @include getFigmaStyle('Shadow/LG');
    }
}