@import "../icon/sass-inline-svg";

$radio-font-size: 15px !default;
$radio-check-size: 10px !default;
$radio-width: 15px !default;
$radio-height: 15px !default;

/**
 * Checkbox container
 */

.radio {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.radio input[type="radio"] {
    display: none;
}

.radio > label {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding-top: calc($radio-height / 6);
    padding-bottom: calc($radio-height / 6);
    @include getFigmaStyle('Desktop/SM/Regular/Default');
}

.radio.radio--small > label {
    font-size: $radio-font-size * 0.925;
}

.radio.radio--large > label {
    font-size: $radio-font-size * 1.125;
}

/**
 * Checkbox pseudo radio
 */

.radio > label input[type="radio"] {
    appearance: none;
    flex: 0 0 auto;
    display: block;
    border: 1px solid $color-primary-800;
    border-radius: 500px;
    width: pxToRem(28);
    height: pxToRem(28);
    color: $color-white;
    background-color: $color-secondary-300;
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('circle', $color-secondary-300);
    transition: background-color 0.1s, border 0.1s;
    margin-right: pxToRem(16);
}

.radio > label:hover input[type="radio"],
.radio > label input[type="radio"]:focus,
.radio > label input[type="radio"]:checked {
    border-color: $color-primary;
}

.radio > label input[type="radio"]:checked {
    background-image: inline-svg('circle', $color-primary-800);
}

.radio.radio--small > label input[type="radio"] {
    font-size: $radio-check-size * 0.925;
    width: $radio-width * 0.925;
    height: $radio-height * 0.925;
}

.radio.radio--large > label input[type="radio"] {
    font-size: $radio-check-size * 1.125;
    width: $radio-width * 1.125;
    height: $radio-height * 1.125;
}

input[type='radio'] {
    transition: all .3s ease;
    &:hover, &:focus, &:active {
        box-shadow: 0 0 0 2px $color-black-alpha-300;
    }
}