
.slider__navigation {
    width: 100%;
    height: 0;
}

.slider__prev,
.slider__next {
    position: absolute;
    top: calc(50% - 25px);
    display: block;
    width: 50px;
    height: 50px;
    background-color: rgba($color-black, 0.3);
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
}

.slider__prev {
    left: 0;
    background-image: inline-svg('angle-left', $color-white);
}

.slider__next {
    right: 0;
    background-image: inline-svg('angle-right', $color-white);
}

.teaser--slider .teaser__item {
    position: relative;
    height: 100%;
    display: block;
    width: 100%;
}