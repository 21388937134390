@import "@sx-core/src/scss/element/calendar.scss";

.calendar__daterange {
    background-color: $color-grey-1200;
    border-radius: 4px;
}

.eventrange__input {
    padding: pxToRem(16);
    text-align: center;

    label {
        display: block;
        padding-bottom: pxToRem(12);
        border-bottom: 1px solid $color-grey-1000;
    }

    &:first-child {
        padding-right: pxToRem(2);
    }

    &:nth-child(2) {
        padding-left: pxToRem(2);
    }
}

#eventRangeSubmit {
    border-radius: 0 0 4px 4px;
}

#eventRangeForm {
    max-width: 280px;
    margin-bottom: $modulspacing-desktop-m;
}

#eventTo, #eventFrom {
    border: none;
}

.calendar__daterange-from > input,
.calendar__daterange-to > input {
    width: 100%;
    height: 175px;
    background-color: $color-grey-1200;
}

.eventrange__input label {
    color: $color-white;
    @include getFigmaStyle('Desktop/MD/Bold/Default');
    border-bottom: 1px solid $color-grey-1000;
}

.eventRangeFrom--coverup, .eventRangeTo--coverup {
    position: absolute;
    top: 37px;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 175px;
    pointer-events: none;
    background-color: $color-grey-1200;
}

.eventRangeFrom--monthYear, .eventRangeTo--monthYear {
    padding: pxToRem(12);
    color: $color-white;
    @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
    white-space: nowrap;
}

.eventRangeFrom--day, .eventRangeTo--day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color-white;
    @include getFigmaStyle('Desktop/H1/Medium/Default');
    background-color: $color-grey-1100;
}