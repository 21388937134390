.popup {
    z-index: 9999999;
    position: relative;
}

.popup__frame {
    position: fixed;
    bottom: 0;
    //left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 200;
    //margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 36px;
    pointer-events: none;
    display: none;
    opacity: 0;
    transform: translateX(100%);
    transition: all 1s ease;
}

.popup h2 {
    font-size: 26px;
    text-align: left;
    margin-bottom: 20px;
    color: $color-white;
    @include getFigmaStyle('Desktop/H4/Regular/Uppercase');
}

.popup p {
    color: $color-white;
    @include getFigmaStyle('Desktop/MD/Regular/Default');
}

.popup a {
    color: $color-white;
    text-decoration: underline;
}

.popup p:last-child {
    margin-bottom: 0;
}

.popup__body {
    width: 360px;
    height: auto;
    background: $color-grey-1100;
    padding: pxToRem(36);
    line-height: 1.4;
    font-size: 17px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    position: relative;
    opacity: 0;
    transform: translateY(0px);
    transition: all 1s ease;
}

.popup__close {
    cursor: pointer;
    position: absolute;
    top: -9px;
    right: -9px;
    display: block;
    width: 36px;
    height: 36px;
    background: $color-primary-800;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 500px;
    transform: rotate(-135deg);
    transition: all .6s ease;
}

.popup__close:hover {
    transform: rotate(45deg);
}

.popup__close:before {
    content: '\00a0';
    position: absolute;
    left: 10px;
    top: calc(50% - 1px);
    display: block;
    width: 15px;
    height: 1px;
    background: $color-white;
}

.popup__close:after {
    content: '\00a0';
    position: absolute;
    left: calc(50% - 1px);
    top: 10px;
    display: block;
    width: 1px;
    height: 15px;
    background: $color-white;
}

.popup__cta {
    display: inline-block;
    margin-top: pxToRem(36);
}

.popup__cta a {
    position: relative;
    display: inline-block;
    padding: pxToRem(6) pxToRem(12);
    text-decoration: none;
    background-color: transparent;
    color: $color-white;
    @include getFigmaStyle('Desktop/XS/Bold/Uppercase');
    border: 1px solid $color-white;
}

.popup__cta a::after {
    content: '';
    position: absolute;
    right: 8px;
    display: block;
    width: 12px;
    height: 12px;
    transition: .4s ease;
}

.popup.popup-open .popup__frame {
    display: flex;
    opacity: 1;
    transform: translateX(0);
}

.popup.popup-open .popup__body {
    pointer-events: auto;
    opacity: 1;
    transform: none;
    transition: opacity 400ms 200ms ease, transform 400ms 200ms ease;
}

@include media-breakpoint-down(sm) {
    .popup.popup-open .popup__frame {
        width: calc(100% - 40px);
        //margin-left: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}