@import "@sx-core/src/scss/base/slider.base.scss";
@import "@sx-core/src/scss/element/slider.scss";

.splide__arrows {
    transition: all .3s ease;
}

.splide__arrow {
    width: pxToRem(72);
    height: pxToRem(72);
    background-color: $color-black-alpha-700;
    background-position: center;
    background-size: 48px;
    background-repeat: no-repeat;
    transition: all .3s ease;

    &--prev {
        left: pxToRem(36);
        background-image: inline-svg('arrow-left-lg', $color-white);
    }

    &--next {
        right: pxToRem(36);
        background-image: inline-svg('arrow-right-lg', $color-white);
    }
}

/*** || Slider LG ***/

.slider-large {
    .splide__track {
        padding-top: pxToRem(36);
        padding-bottom: pxToRem(72);
    }

    .splide__arrow {
        top: 40%;
    }
}

.slider-item-large {
    @include getFigmaStyle('Shadow/SM');
    transition: all .3s ease;

    &__image {
        position: relative;
    }

    &__headline--title {
        padding: pxToRem(15) pxToRem(16);
        color: $color-white;
        @include getFigmaStyle('Mobile/MD/Regular/Uppercase');
        text-align: center;
        background-color: $color-grey-1200;
        transition: all .3s ease;
    }

    &__body {
        position: relative;
        padding: pxToRem(28) pxToRem(28) pxToRem(48);
        color: $color-grey-1000;
        text-align: center;
        background-color: $color-white;
        margin-bottom: pxToRem(18);
    }

    &__readmore {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }

    @include media-breakpoint-up(lg) {
        &:hover, &:focus, &:active {
            box-shadow: 0 4px 36px rgba(0, 0, 0, .2);

            .slider-item-large__headline--title {
                background-color: $color-grey-1300;
            }
        }
    }
}

.splide__pagination {
    bottom: 0;
    column-gap: pxToRem(10);

    &__page {
        width: pxToRem(16);
        height: pxToRem(16);
        border-radius: 50%;
        background-color: $color-grey-600;
    }

    &__page.is-active {
        background-color: $color-grey-1200;
        transform: initial;
    }
}

@include media-breakpoint-down(md) {
    .splide__arrow {
        width: 40px;
        height: 40px;

        &--prev {
            left: pxToRem(16);
            background-image: inline-svg('arrow-left-sm', $color-white);
            background-size: 16px;
        }

        &--next {
            right: pxToRem(16);
            background-image: inline-svg('arrow-right-sm', $color-white);
            background-size: 16px;
        }
    }
}

/*** Slider SM ***/

.slider-small {
    .splide__track {
        padding-top: pxToRem(36);
        padding-bottom: pxToRem(72);
    }
}

.slider-small__item {
    display: block;
}

.slider-item-small {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include getFigmaStyle('Shadow/SM');
    transition: all .3s ease;

    &__body {
        position: relative;
        flex: 1 1 100%;
        background-color: $color-grey-1200;
    }

    &__headline {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: pxToRem(15) pxToRem(8);
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
        text-align: center;
        background-color: $color-grey-1200;
        transition: all .3s ease;
    }

    @include media-breakpoint-up(lg) {
        &:hover, &:focus, &:active {
            box-shadow: 0 4px 36px rgba(0, 0, 0, 0.2);

            .slider-item-small__headline {
                transform: translateY(-6px);
            }
        }
    }
}