.eventdate.eventdate--is-selected {
    margin: pxToRem(40) 0;
}

.eventdate {
    span {
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }
}

.eventseries {
    margin-top: pxToRem(64);
    row-gap: pxToRem(16);
    justify-content: center;

    .eventdate.eventdate--is-selected {
        margin: initial;
    }

    a {
        display: inline-block;

        &:hover, &:focus {
            .eventdate {
                background-color: $color-primary-900;
            }
        }
    }

    .eventdate {
        display: inline-block;
        color: $color-white;
        background-color: $color-primary-800;
        padding: pxToRem(6) pxToRem(12);
        border-radius: 4px;
        transition: all .3s ease;
    }
}

.eventseries__eventdate {
    .eventdate.eventdate--is-selected {
        display: none;
        margin: initial;
        color: $color-white;
        background-color: $color-black;
    }

    .eventdate {
        padding: pxToRem(6) pxToRem(12);
        margin: 0 pxToRem(8);
        border: 1px solid $color-black;
        transition: all .3s ease;

        @include media-breakpoint-up(lg) {
            &:hover, &:focus {
                color: $color-white;
                background-color: $color-black;
            }
        }
    }
}

.calendar-group__teaser > .grid {
    row-gap: pxToRem(48);
}