
/***************************
 *   Fastbooking Styles
 *************************** */
.fastbooking {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 99;

    .button.button--primary.button--md {
        width: 100%;

        //@include media-breakpoint-down(sm) {
        //    padding-left: 0;
        //    padding-right: 0;
        //}
    }

    .left {
        .button.button--primary.button--md {
            border-radius: 0 0 0 4px;
            background-color: rgba($color-primary-800, .8);
        }
    }

    .right {
        .button.button--primary.button--md {
            border-radius: 0 0 4px 0;
        }
    }

    .input-group {
        flex-wrap: wrap;
    }
}

@include media-breakpoint-down(sm) {
    .header__buttons {
        width: 100%;
    }
}


.fb {
    display: flex;
    padding: 0px 18px 13px 18px;
    //background: #A7B0B6;
    background-color: $color-grey-1200;
}

.fb > div {
    width: 33.333%;
    padding: 0px 3px;
}

.fb.no-chooser {
    padding: 20px 20px 13px 20px;
}

.fb__bcode--outer {
    padding: 0 23px 30px;
    //background: #A7B0B6
    background-color: $color-grey-1200;
}

.fb__bcode {
    border-radius: 0 !important;
    border: none !important;
    width: 100%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2) !important;
}


.event-datepicker .fb > div {
    width: 50%;
}


//.fb__title {
//    display: block;
//    padding: 8px 0px 8px 0px;
//    font-size: 13px;
//    font-size: 0.8125rem;
//    background: #378bce;
//    background: -moz-linear-gradient(top, #378bce 0%, #236fac 100%);
//    background: -webkit-linear-gradient(top, #378bce 0%, #236fac 100%);
//    background: linear-gradient(to bottom, #378bce 0%, #236fac 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#378bce', endColorstr='#236fac',GradientType=0 );
//    color: #ffffff;
//    line-height: 1em;
//    text-align: center;
//}

.fb__title {
    display: inline-block;
    width: 100%;
    padding-bottom: pxToRem(12);
    color: $color-white;
    @include getFigmaStyle('Desktop/MD/Bold/Default');
    border-bottom: 1px solid $color-grey-1000;
}

.fb__date-month {
    display: block;
    width: 100%;
    padding: pxToRem(12) 0;
    text-align: center;
    color: $color-white;
    @include getFigmaStyle('Desktop/MD/Regular/Default');
    background-color: $color-grey-1200;
}

//.fb__date-month, .fb__date-day {
//    background: #ffffff;
//    display: block;
//    text-align: center;
//    width: 100%;
//    font-size: 15px;
//    font-size: 0.9375rem;
//    padding: 9px 0 9px;
//    border-bottom: 1px solid #EFF2F5;
//}


.fb__date-day {
    display: block;
    width: 100%;
    text-align: center;
    padding: pxToRem(28) 0;
    color: $color-white;
    @include getFigmaStyle('Desktop/H1/Medium/Default');
    background-color: $color-grey-1000;
}


//.fb__btn {
//    border-radius: 0px;
//    border-bottom-left-radius: 10px;
//    border-bottom-right-radius: 10px;
//    overflow: hidden;
//    width: 100%;
//    color: #fff;
//    background: #378bce;
//    background: -moz-linear-gradient(top, #378bce 0%, #236fac 100%);
//    background: -webkit-linear-gradient(top, #378bce 0%, #236fac 100%);
//    background: linear-gradient(to bottom, #378bce 0%, #236fac 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#378bce', endColorstr='#236fac',GradientType=0 );
//    font-size: 20px;
//    font-size: 1.25rem;
//    border: none;
//    padding: 10px 0;
//}
//
//.fb__btn:hover {
//    background: #378bce;
//    background: -moz-linear-gradient(top, #236fac 0%, #378bce 100%);
//    background: -webkit-linear-gradient(top, #236fac 0%, #378bce 100%);
//    background: linear-gradient(to bottom, #236fac 0%, #378bce 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#236fac', endColorstr='#378bce',GradientType=0 );
//}


.fb-wrapper {
    height: 46px;
    position: relative;
    border-bottom: 1px solid #3c3c3c;
}


.fastbooking {
    text-align: center;
    z-index: 10;
    width: 462px;
    position: relative;
    //margin: auto;
    //position: absolute;
    //left: calc(50% - 231px);
    //left: calc(50% - 231px);
}

.fastbooking .left, .fastbooking .right {
    width: 50%;
    float: left;
}

.fastbooking .row {
    display: block;
}

//.fastbooking .btn-fastbook-custom, .fastbooking .btn-fastbook-open {
//    font-size: 20px;
//    font-size: 1.25rem;
//    color: #ffffff !important;
//    display: block;
//    padding-top: 12px;
//    padding-bottom: 12px;
//}

//.fastbooking .btn-fastbook-custom {
//    background: #378bce;
//    background: -moz-linear-gradient(top, rgba(55, 139, 206, 0.7) 0%, rgba(35, 111, 172, 0.85) 100%);
//    background: -webkit-linear-gradient(top, rgba(55, 139, 206, 0.7) 0%, rgba(35, 111, 172, 0.85) 100%);
//    background: linear-gradient(to bottom, rgba(55, 139, 206, 0.7) 0%, rgba(35, 111, 172, 0.85) 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#378bce', endColorstr='#236fac',GradientType=0 );
//    border-bottom-left-radius: 10px;
//}
//
//.fastbooking .btn-fastbook-custom:hover {
//    background: -moz-linear-gradient(top, rgba(35, 111, 172, 0.7) 0%, rgba(55, 139, 206, 0.85) 100%);
//    background: -webkit-linear-gradient(top, rgba(35, 111, 172, 0.7) 0%, rgba(55, 139, 206, 0.85) 100%);
//    background: linear-gradient(to bottom, rgba(35, 111, 172, 0.7) 0%, rgba(55, 139, 206, 0.85) 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#236fac', endColorstr='#378bce',GradientType=0 );
//}
//
//.fastbooking .btn-fastbook-open {
//    background: #378bce;
//    background: -moz-linear-gradient(top, #378bce 0%, #236fac 100%);
//    background: -webkit-linear-gradient(top, #378bce 0%, #236fac 100%);
//    background: linear-gradient(to bottom, #378bce 0%, #236fac 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#378bce', endColorstr='#236fac',GradientType=0 );
//    cursor: pointer;
//    border-radius: 0 0 10px 10px;
//}
//.fastbooking .btn-fastbook-open:hover {
//    background: #378bce;
//    background: -moz-linear-gradient(top, #236fac 0%, #378bce 100%);
//    background: -webkit-linear-gradient(top, #236fac 0%, #378bce 100%);
//    background: linear-gradient(to bottom, #236fac 0%, #378bce 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#236fac', endColorstr='#378bce',GradientType=0 );
//}

.fastbooking .form-group span.title {
    padding-top: 8px;
    margin-bottom: 3px;
    color: #fff;
}

.fastbooking span.date-top {
    font-weight: bold;
    background: #0088CC;
    color: #fff;
    float: left;
    width: 100%;
    height: 47px;
    font-size: 35px;
    line-height: 47px;
}

.fastbooking span.date-bottom {
    font-weight: bold;
    border-top: none;
    color: #fff;
    background: #0088CC;
    float: right;
    width: 100%;
    height: 27px;
    line-height: 27px;
    font-size: 14px;
}

.fastbooking input[type="text"] {
    height: auto;
    color: $color-white;
    text-align: center;
    background-color: $color-grey-1000;

    &::placeholder {
        color: $color-white;
    }
}

.fastbooking .input-group {
    width: 100%;
}

.fastbooking .form-group {
    cursor: pointer;
    //margin-bottom: 4px;
    margin-top: pxToRem(16);
}

.fastbooking .left .form-group, .fastbooking .right .form-group {
    padding: 5px;
    background: #0088CC;
}

.fastbooking .fields-container {
    display: none;
}

.fastbooking .fields-container.chosen-with-drop .chosen-single {
    color: #0088CC;
}


.motel-chooser {
    padding: pxToRem(16);
    padding-bottom: 0;
    //background: rgba(71, 80, 86, 0.8);
    background-color: $color-grey-1200;
}

.motel-chooser .title,
.motel-chooser .radio {
    z-index: 10;
    position: relative;
}

.motel-chooser .title {
    color: $color-white;
    @include getFigmaStyle('Desktop/XS/Bold/Default');
}

.motel-chooser .radio {
    width: 100%;
}

.motel-chooser .radio.active {
    background-color: $color-primary-800;
}

.motel-chooser .radio {
    display: block;
    padding: pxToRem(7) pxToRem(12);
    color: $color-white;
    @include getFigmaStyle('Desktop/MD/Regular/Default');
    background-color: $color-grey-1000;
    border-radius: 4px;

    &:not(:last-child) {
        margin-bottom: pxToRem(4);
    }
}

.motel-chooser .radio label {
    background: #fff;
    display: block;
    position: relative;
    z-index: 10;
    top: -20px;
    width: 100%;
    text-align: left;
    padding: 10px;
    position: absolute;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.motel-chooser .radio label:hover {
    -webkit-box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2);
}

.motel-chooser .radio label:after {
    content: '\e81c';
    font-family: 'sx-icon';
    position: absolute;
    right: 20px;
    font-size: 20px;
    color: #3184C7;
}

.motel-chooser .radio-hhf {
    margin-bottom: 26px;
}

.motel-chooser span.title {
    display: block;
    margin-bottom: pxToRem(16);
    @include getFigmaStyle('Desktop/XS/Bold/Default');
    color: #fff;
    position: relative;
    z-index: 1000;
}

.gschooserbutton__outer {
    position: relative;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: pxToRem(4);
    }
}

.gschooserbutton {
    display: block;
    width: 100%;
}

.fb_subtext {
    color: #fff;
    font-size: 13px;
    padding: 0 58px 16px;
    line-height: 1.35em;
    //background: #A7B0B6;
    margin-top: -10px;
    position: relative;
    z-index: 1000;
    display: none;
    background-color: $color-grey-1200;
}

.fb_subtext.show {
    display: block;
}


.btn-fastbook.submit {
    position: relative;
    z-index: 1000;
}


.motel-chooser {
    //padding: pxToRem(16);
    //background: #A7B0B6;
    position: relative;
    z-index: 1000;
}

.gs__form .motel-chooser {
    padding-bottom: pxToRem(16);
}

.motel-chooser.js-deactivated + .fb {
    padding: 0px 18px 14px 18px;
}

.motel-chooser.js-deactivated + .fb * {
    filter: blur(1px);
}

.motel-chooser.js-deactivated:before {
    content: '';
    width: 100%;
    //background: rgba(167, 176, 182, 0.6);
    height: 470px;
    display: block;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}


.js-fb-unchecked {
    position: absolute;
    top: 0;
    width: 100%;
}

.js-fb-unchecked label {
    background: #dedede !important;
    box-shadow: none !important;
    color: #696969 !important;
}

.js-fb-unchecked label:hover {
    background: #fff !important;
}


.fb__date-day--adults {
    //padding: 28px 0 33px;
}


.fb__input-adults-value {
    padding-right: 11px;
}


.fb__input-adults {
    width: 42%;
    background: #ffffff;
    border: none;
    box-shadow: none;
    text-align: center;
    margin-left: -15px;
    font-size: 42px;
    font-size: 2.625rem;
    height: 38px;
    overflow: inherit;
}


.chosen-container-single .chosen-single div b {
    background: none;
}


.fb-right {
    position: relative;
}


.fb-adults__chooser {
    position: absolute;
    right: 19px;
    bottom: 28px;
    z-index: 100;

    @include media-breakpoint-down(md) {
        bottom: 18px;
    }
}


.fb-adults__up {
    width: 28px;
    height: 28px;
    display: block;
    position: relative;
    border-radius: 500px;
    //background: rgba(12, 119, 189, 0.6);
    background-image: inline-svg('angle-filled-up', $color-white);
    background-size: 28px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 5px;
    transition: border 0.3s, background 0.3s;
}

.fb-adults__up:hover,
.fb-adults__down:hover {
    //background: rgba(12, 119, 189, 1);
}

//.fb-adults__up:before {
//    content: '\00a0';
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    width: 0;
//    height: 0;
//    margin-top: -3px;
//    background-image: inline-svg('angle-filled-up', $color-white);
//    background-size: 28px;
//    background-position: center;
//    background-repeat: no-repeat;
//    border: 5px solid transparent;
//    border-bottom: 5px solid #fff;
//    transform: translate(-50%, -50%);
//}

.fb-adults__down {
    width: 28px;
    height: 28px;
    display: block;
    position: relative;
    border-radius: 500px;
    //background: rgba(12, 119, 189, 0.6);
    background-image: inline-svg('angle-filled-down', $color-white);
    background-size: 28px;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 5px;
    transition: border 0.3s, background 0.3s;
}
//
//.fb-adults__down:before {
//    content: '\00a0';
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    width: 0;
//    height: 0;
//    margin-top: 3px;
//    border: 5px solid transparent;
//    border-top: 5px solid #fff;
//    transform: translate(-50%, -50%);
//}


.fastbooking .chosen-container-single .chosen-single span {
    margin: 0;
}


.fastbooking .chosen-container-single .chosen-drop {
    z-index: 999;
    border: none;
}


.fastbooking .chosen-container .chosen-results {
    margin: 0;
    padding: 0;
}

.fastbooking .chosen-container .chosen-results li.highlighted {
    background: #0088CC;
    color: #fff;
}


.fastbooking .spacer {
    display: block;
    height: 4px;
}


.fastbooking .btn-secondary {
    width: 100%;
    margin-bottom: 6px;
    background: #f5f5f5;
    border-color: #f5f5f5;
    color: #3184C7;
    font-size: 14px;
}

.fastbook__form {
    display: none;
}

.fbopen .fastbook__form {
    display: block;
}

.gs__form {
    display: none;
}

.gsopen .gs__form {
    display: block;
}

@media (max-width: 1051px ) {
    .fastbooking {
        //top: 90px;
    }
}

@media (max-width: 474px) {
    .fb {
        padding: 0px 20px 14px 20px;
    }

    .fastbooking {
        width: 100%;
        left: 0px;
    }
}

@include media-breakpoint-down(sm) {
    .fastbooking .fb-left .bootstrap-datetimepicker-widget {
        left: 0 !important;
    }
}