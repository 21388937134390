@import "@sx-core/src/scss/base/button.base.scss";

$btn-font-family: $font-family-primary;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-border-radius: 4px;
$btn-border-width: 1px;
$btn-border-style: solid;

$btn-disabled-opacity: 1;
$btn-box-shadow: null;
$btn-focus-width: $btn-border-width;
$btn-focus-box-shadow: 0 0 0 $btn-focus-width $color-white;
$btn-active-box-shadow: null;

$scale-factor-sm: 0.75;
$scale-factor-lg: 1.25;

/*default*/

$btn-padding-x: pxToRem(40); //rechts
$btn-padding-y: pxToRem(14); //oben
$btn-font-size: pxToRem(18);
$btn-line-height: 1.5;
$btn-border-radius: $btn-border-radius;

/*md*/
$btn-padding-x-md: $btn-padding-x;
$btn-padding-y-md: $btn-padding-y;
$btn-font-size-md: pxToRem(18);
$btn-line-height-md: $btn-line-height;
$btn-border-radius-md: $btn-border-radius;

/*sm*/
$btn-padding-x-sm: pxToRem(12);
$btn-padding-y-sm: pxToRem(6);
$btn-font-size-sm: pxToRem(18);
$btn-line-height-sm: $btn-line-height;
$btn-border-radius-sm: $btn-border-radius;

/*lg*/
$btn-padding-x-lg: $btn-padding-x*$scale-factor-lg;
$btn-padding-y-lg: $btn-padding-y*$scale-factor-lg;
$btn-font-size-lg: pxToRem(18);
$btn-line-height-lg: $btn-line-height;
$btn-border-radius-lg: $btn-border-radius;


/*ICON*/

$btn-icon-size: 24px;


@mixin button-variant(
    $background-color,
    $border-color,


    $color: text-contrast($background-color),

    $hover-background-color: darken($background-color,30%),
    $hover-border-color: darken($background-color,30%),
    $hover-color: text-contrast($hover-background-color),

    $active-background-color: lighten($background-color,30%),
    $active-border-color: lighten($background-color,30%),
    $active-color: text-contrast($active-background-color),

    $disabled-background-color: $color-grey-500,
    $disabled-border-color: $color-grey-500,
    $disabled-color: text-contrast($color-white),

    $border-width: $btn-border-width,
    $border-style: $btn-border-style

) {


    color: $color;
    background-color: $background-color;
    border-color: $border-color;
    border-width: $border-width;
    border-style: $border-style;
    //box-shadow: $shadow;

    &:hover {
        color: $hover-color;
        background-color: $hover-background-color;
        border-color: $hover-border-color;
    }

    &:focus {
        color: $hover-color;
        background-color: $hover-background-color;
        border-color: $hover-border-color;
        outline-color: $hover-border-color;
    }

    &:active {
        color: $active-color;
        background-color: $active-background-color;
        border-color: $active-border-color;
        outline-color: $active-border-color;
    }

    &:disabled,
    &.disabled {
        color: $disabled-color;
        background-color: $disabled-background-color;
        border-color: $disabled-border-color;
    }
}

@mixin button-size($padding-y, $padding-x, $font-size, $border-radius, $line-height: null) {
    @debug ($padding-y);
    @debug $padding-y - pxToRem(1px);
    //--#{$boosted-variable-prefix}icon-spacing: #{$icon-spacing}; : used for icons
    //padding: ($padding-y - pxToRem(1px)) $padding-x ($padding-y + pxToRem(1px));
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    border-radius: $border-radius

    //TODO Icon Size & Paddings & Margins
}


@mixin button-icon(
    $icon: 'arrow-right',
    $pseudo: after,

    $width: 24px,
    $height: $width,

    $icon-width: 24px,
    $icon-height: $icon-width,

    $margin-left: 50px,
    $margin-right: 6px,

    $border-radius: 50%,
    $border-color: $color-black,
    $border-width: $btn-border-width,

    $color: $color-black,
    $fillcolor: transparent,
	$strokecolor: $color,

) {

    display: flex;
    align-items: center;

    @if $pseudo == before {
        padding-left: $margin-left;
    }

    @if $pseudo == after {
        padding-right: $margin-right;
    }

    &:#{$pseudo} {
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: $border-radius;
        width: $width;
        height: $height;

        background: inline-svg($icon, $color, $fillcolor, $strokecolor);
        background-size: $icon-width $icon-height;
        background-repeat: no-repeat;
        background-position: center center;

        @if $pseudo == before {
            margin-right: $margin-right;
        }

        @if $pseudo == after {
            margin-left: $margin-left;
        }
    }


    &:hover {
        //TODO
    }

    &:focus {
        //TODO
    }

    &:active {
        //TODO
    }

    &:disabled,
    &.disabled {
        //TODO
    }


}


a.button, .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include getFigmaStyle('Desktop/MD/Medium/Default');
    transition: all .3s ease;
}


/* Button sizes*/

.button--sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm, $btn-line-height-sm);
}

.button--md {
    min-height: pxToRem(56);
    @include button-size($btn-padding-y-md, $btn-padding-x-md, $btn-font-size-md, $btn-border-radius-md, $btn-line-height-md);
}

.button--lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg, $btn-line-height-lg);
}


/*Buttons bauen */

.button--primary {
    @include button-variant($color-primary-800, $color-primary-800, $color-white,
    $hover-background-color: $color-primary-900,
    $hover-border-color: $color-primary-900,
    $active-background-color: $color-primary-900,
    $active-border-color: $color-primary-900);
}

/* Outline example */
.button--secondary {
    @include button-variant(transparent, $color-primary-800, $color-white,
    $hover-background-color: $color-primary-900,
    $hover-border-color: $color-primary-900,
    $active-background-color: $color-primary-900,
    $active-border-color: $color-primary-900);
}

.button--md.button--nailed, .button--sm.button--nailed {
    padding-left: pxToRem(48);
    padding-right: pxToRem(48);
}

.button--primary.button--nailed {
    position: relative;
    @include button-variant($color-primary-800, $color-primary-800, $color-white,
    $hover-background-color: $color-primary-900,
    $hover-border-color: $color-primary-900,
    $active-background-color: $color-primary-900,
    $active-border-color: $color-primary-900);

    &::before, &::after {
        content: '';
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        background-image: inline-svg('dot', $color-primary-900, $color-primary-900, $color-primary-1000);
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
        transition: all .3s ease;
    }

    &::before {
        left: 12px;
    }

    &::after {
        right: 12px;
    }

    &:hover, &:focus, &:active {
        &::before, &::after {
            background-image: inline-svg('dot', $color-white, $color-white, $color-white);
        }
    }
}

.button--default, .button {

}

.button--icon {
    //@include button-icon();
}

.button--default.button--icon, .button.button--icon {

}

.button--link {

}

.button--link.button--icon {

}

.cta {
    display: inline-flex;
    justify-content: center;
    column-gap: pxToRem(8);
    margin-top: pxToRem(48);
}