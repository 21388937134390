
h1, .h1, h2, .h2 {

}

@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin-bottom: pxToRem(16);
    }

    h1, .h1 {
        @include getFigmaStyle('Desktop/H1/Bold/Default');
    }

    h2, .h2 {
        @include getFigmaStyle('Desktop/H2/Bold/Default');
    }

    h3, .h3 {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }

    h4, .h4 {
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    h5, .h5 {
        @include getFigmaStyle('Desktop/H5/Bold/Default');
    }

    h6, .h6 {
        @include getFigmaStyle('Desktop/H6/Bold/Default');
    }

    p {
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    p:not(:last-child) {
        margin-bottom: pxToRem(16);
    }

    a strong {
        color: $color-primary;
    }

    .content a, &.content a {
        color: $color-black;
        text-decoration: underline;
    }

    ul, ol {
        margin: pxToRem(48) 0;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        padding-left: pxToRem(4);
        margin-bottom: pxToRem(8);
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        //column-count: 1;
        //column-gap: pxToRem(48);
    }

    ul li {
        position: relative;
        padding-left: pxToRem(30);
        margin-bottom: pxToRem(20);
        text-align: left;
        width: 100%;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    ul li.list-centered {
        width: auto;
    }

    ul li:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: pxToRem(24);
        height: pxToRem(24);
        background-image: inline-svg('dot', $color-primary);
        background-repeat: no-repeat;
        background-size: pxToRem(24);
        background-position: 0 center;
    }

    @include media-breakpoint-down(md) {
        ul {
            column-count: 1;
        }
    }

    ol ol, ul ul, ol ul, ul ol {
        margin: pxToRem(8) 0;
    }

    .table-scrollable {
        width: 100%;
        overflow-x: auto;
    }

    table {
        //display: block;
        //overflow-x: auto;
        text-align: left;
        width: 100%;
        margin: pxToRem(28) 0;

        ul {
            column-count: 1;
        }

        tbody {
            //display: table;
            //overflow-x: auto;
            width: 100%;
        }
    }

    thead tr:last-child {
        border-bottom: 2px solid $color-grey-500;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-grey-500;
    }

    th, td {
        padding: pxToRem(20) pxToRem(12) pxToRem(20) 0;
    }

    em, i {
        font-style: italic;
    }

    hr {
        width: pxToRem(90);
        height: 1px;
        margin: pxToRem(48) auto;
        background-color: $color-primary-800;
    }

}